import React, {useContext, useState, useEffect} from 'react';
import {Button, Drawer, IconButton, useTheme} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import UserContext from '../../context/user';
import Link from 'next/link';
import {useRouter} from 'next/router';
import theme from '../../styles/theme';
import PromoBannerV2 from '../PromoBannerV2';

const useStyles = makeStyles((theme: any): any => ({
  relativeWrapper: {
    height: '8vh',
    position: 'relative',
    backgroundColor: `${theme.palette.common.lightGray}DE`,
    backdropFilter: 'blur(16px)',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  stickyWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1200,
    height: '9vh',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    transition: '0.7s ease-in-out',
  },
  headerScrolled: {
    height: '8vh',
    backgroundColor: `${theme.palette.common.lightGray}DE`,
    backdropFilter: 'blur(16px)',
    transition: '0.7s ease-in-out',
  },
  container: {
    flex: 1,
    minHeight: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
  },
  mainLogo: {
    width: '170px',
    margin: '10px 36px',
  },
  headerButton: {
    padding: '0.5em 1em',
    fontSize: 'clamp(15px, 1.1vw, 17px)',
    borderRadius: 100,
    margin: '0px 16px',
    [theme.breakpoints.down(1282)]: {
      alignSelf: 'stretch',
    },
    [theme.breakpoints.down(1188)]: {
      margin: 8,
    },
  },
  linkButton: {
    background: 'none',
    outline: 'none',
    border: 'none',
    ...theme.typography.h1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerLink: {
    position: 'relative',
    display: 'inline-block',
    color: theme.palette.common.lightGray,
    fontSize: 'clamp(16px, 1.2vw, 18px)',
    textDecoration: 'none',
    lineHeight: '21px',
    fontWeight: 500,
    margin: '0px 8px',
    padding: '0 0 2px',
    textShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',

    '&:hover': {
      backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift 1s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23000000' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E")`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'repeat-x',
      backgroundSize: 'auto 4px',
    },
  },
  headerLinkDark: {
    position: 'relative',
    display: 'inline-block',
    color: theme.palette.common.black,
    fontSize: 'clamp(16px, 1.2vw, 18px)',
    textDecoration: 'none',
    lineHeight: '21px',
    fontWeight: 500,
    margin: '0px 8px',
    padding: '0 0 2px',

    '&:hover': {
      backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift 1s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23000000' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E")`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'repeat-x',
      backgroundSize: 'auto 4px',
    },
  },
  headerText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    textDecoration: 'none',
    letterSpacing: 1.5,
    fontWeight: 600,
    padding: '3px',
    float: 'right !important',
    clear: 'both',
    display: 'table',
  },
  drawer: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.lightGray,
    padding: '40px 30px 40px',
    maxWidth: 220,
    width: '100%',
  },
  menuBtn: {
    display: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(1282)]: {display: 'block'},
  },
  columnBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
    [theme.breakpoints.down(1282)]: {gap: '1rem'},
  },
  logoWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#d8dbdd',
    cursor: 'pointer',
  },
  flexBtns: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textAlign: 'center',
    },
  },
  logo: {
    float: 'left',
    marginLeft: '3.5vw',
    height: 70,
    color: 'white',
    verticalAlign: 'middle',
    '& img': {
      float: 'left',
      height: 50,
      color: 'white',
    },
    '& p': {
      marginLeft: 35,
      fontSize: 'clamp(13px, 1vw, 14px)',
      fontWeight: 700,
    },
    [theme.breakpoints.down(860)]: {
      '& img': {
        height: 40,
      },
    },
    [theme.breakpoints.down(400)]: {
      '& p': {
        display: 'none',
      },
    },
  },
}));

const Header = () => {
  const classes: any = useStyles();
  const router = useRouter();
  const theme: any = useTheme();

  const {isLoggedIn, logout, paymentType, paymentStatus} =
    useContext(UserContext);
  const [width, setWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [where, setWhere] = useState('');
  const [stripeId, setStripeId] = useState('');
  const [scrolled, setScrolled] = useState(false);

  const isMapPage = where.includes('map');
  const routesWithSolidHeader = ['map', 'jobs/', 'user-guide', 'checkout', 'account'];
  const routesWithAllHiddenLinks = ['subscribe', 'login'];
  const routesWithCenterHiddenLinks = ['map', 'account', 'checkout'];
  const isSolidHeader = routesWithSolidHeader.some(route =>
    where.includes(route),
  );
  const hideAllNavLinks = routesWithAllHiddenLinks.some(route =>
    where.includes(route),
  );
  const hideCenterNavLinks = routesWithCenterHiddenLinks.some(route =>
    where.includes(route),
  );

  const useSolidHeaderStyles = scrolled || isSolidHeader;
  const headerLinkClassName = useSolidHeaderStyles
    ? classes.headerLinkDark
    : classes.headerLink;
  const logoSrc = useSolidHeaderStyles
    ? '/assets/images/logo/onwater-logo-combo-horizontal-dark.svg'
    : '/assets/images/logo/onwater-logo-combo-horizontal.svg';

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setScrolled(window.pageYOffset > 80),
    );

    return () => {
      window.removeEventListener('scroll', () =>
        setScrolled(window.pageYOffset > 80),
      );
    };
  }, []);

  // const userName =  JSON.parse(localStorage.getItem("USER"));
  // LogRocket.identify(userName)

  // getting stripe_id and passing it to form so that user can manage their stripe session
  const parseJwt = token => {
    if (!token) {
      return;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const userToken = localStorage.getItem('TOKEN');
    if (userToken) {
      let decoded = parseJwt(userToken);
      let id = decoded.stripe_id;
      if (id) {
        setStripeId(id);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setWhere(location.pathname);
  }, [router.pathname]);

  useEffect(() => {
    setOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const loggedOutButtons = (direction?, size?) => {
    let columnStyle = direction === 'col' ? true : false;
    const linkStyle = columnStyle
      ? classes.headerLinkDark
      : headerLinkClassName;

    return (
      <>
      {hideCenterNavLinks ? null : (
        <div className={columnStyle ? classes.columnBtns : classes.flexBtns}>
          <a
            href="https://www.onwaterapp.com"
            className={linkStyle}
            children={'About'}
          />
          {/* <Link href={'/gift-card/buy'}>
          <a className={linkStyle} children={'Giftcard'} />
        </Link> */}
          <a
            href="https://www.onwaterapp.com/pricing"
            className={linkStyle}
            children={'Pricing'}
          />
          {/* <Link href="/smith-river-map/">
          <a className={linkStyle} children={'Sample River'} />
        </Link> */}
          {/* <Link href="/user-guide">
          <a className={linkStyle} children={'User Guide'} />
        </Link> */}
          <a
            href="https://www.onwaterapp.com/conservation"
            className={linkStyle}
            children={'Conservation'}
          />
          <a
            href="https://www.onwaterapp.com/blog"
            target="_blank"
            rel="noopener noreferrer"
            className={linkStyle}
            children={'Blog'}
          />
          <a
            href="https://www.onwaterapp.com/contact"
            className={linkStyle}
            children={'Support'}
          />
          <Link href="/gift">
            <a className={linkStyle} children={'Gift'} />
          </Link>
        </div>
      )}
        <div
          className={columnStyle ? classes.columnBtns : classes.flexBtns}
          style={columnStyle ? {marginTop: '1rem'} : null}>
          <Link href={'/login'}>
            <a className={linkStyle} children={'Log In'} />
          </Link>
          <Link href={'/subscribe'}>
            <Button
              variant="contained"
              color="primary"
              className={classes.headerButton}
              children={'Sign Up'}
            />
          </Link>
        </div>
      </>
    );
  };

  const loggedInButtons = (direction?, size?) => {
    let columnStyle = direction === 'col' ? true : false;
    const linkStyle = columnStyle
      ? classes.headerLinkDark
      : headerLinkClassName;

    return (
      <>
      {hideCenterNavLinks ? null : (
        <div className={columnStyle ? classes.columnBtns : classes.flexBtns}>
          <a
            href="https://www.onwaterapp.com"
            className={linkStyle}
            children={'About'}
          />
          {/* <Link href="/user-guide">
          <a className={linkStyle} children={'User Guide'} />
        </Link> */}
          <a
            href="https://www.onwaterapp.com/conservation"
            className={linkStyle}
            children={'Conservation'}
          />
          <a
            href="https://www.onwaterapp.com/blog"
            target="_blank"
            rel="noopener noreferrer"
            className={linkStyle}
            children={'Blog'}
          />
          {/* <Link href={paymentStatus ? '/map' : '/mapv2'}>
            <a className={linkStyle} children={'Explore Maps'} />
          </Link> */}
          <a
            href="https://www.onwaterapp.com/contact"
            className={linkStyle}
            children={'Support'}
          />
          <Link href="/gift">
            <a className={linkStyle} children={'Gift'} />
          </Link>
        </div>
      )}
        <div
          className={columnStyle ? classes.columnBtns : classes.flexBtns}
          style={columnStyle ? {marginTop: '1rem'} : null}>
          <Link href={'/account'}>
            <a className={linkStyle} children={'Account'} />
          </Link>
          <Button
            variant="contained"
            color="primary"
            className={classes.headerButton}
            onClick={async () => {
              const redirect = await logout();
              if (redirect) {
                router.push('/login');
              }
            }}>
            Logout
          </Button>
        </div>
      </>
    );
  };

  const buttonHamburger = () => {
    return (
      <>
        <IconButton
          className={classes.menuBtn}
          color="inherit"
          aria-label="Menu"
          aria-controls="drawer"
          aria-haspopup="true"
          onClick={() => setOpen(true)}
          style={
            useSolidHeaderStyles
              ? {
                  color: theme.palette.common.black,
                }
              : {
                  color: theme.palette.common.lightGray,
                }
          }>
          <MenuIcon />
        </IconButton>

        <Drawer
          classes={{paper: classes.drawer}}
          id="drawer"
          open={open}
          onClose={() => setOpen(false)}>
          {isLoggedIn ? loggedInButtons('col') : loggedOutButtons('col')}
        </Drawer>
      </>
    );
  };

  return (
    <>
      <nav
        className={`${
          isMapPage ? classes.relativeWrapper : classes.stickyWrapper
        } ${scrolled ? classes.headerScrolled : ''}`}>
        <div className="branch-journeys-top" />
        <div className={classes.container}>
          <a href="https://www.onwaterapp.com/" className={classes.logoWrapper}>
            <img
              className={classes.mainLogo}
              src={logoSrc}
              alt="Onwater Logo"
            />
          </a>
          {(width > 830 && !hideAllNavLinks)
            ? isLoggedIn
              ? loggedInButtons()
              : loggedOutButtons()
            : buttonHamburger()}
        </div>
      </nav>
    </>
  );
};

export default Header;
